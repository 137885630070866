<script setup>
import {
  IonRefresher,
  IonRefresherContent,
  IonItem,
  IonTitle,
  IonList,
  IonHeader,
  IonButtons,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonCardSubtitle,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonContent,
  IonToolbar,
  menuController,
  IonAvatar,
  IonAccordion,
  IonAccordionGroup,
  IonMenu,
  IonRouterOutlet,
  IonApp,
  IonText,
  IonSegment,
  IonBadge,
  IonSegmentButton,
  IonSearchbar,
  alertController,
  useIonRouter,
} from "@ionic/vue";

import {useGlobalStore} from "./store/global";
import {Authenticator} from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";

import {fetchUserAttributes} from "aws-amplify/auth";
import {Hub} from "aws-amplify/utils";

import {onMounted, ref} from "vue";
import {useRoute} from "vue-router"

import {QSeparator, QSkeleton, useQuasar} from "quasar";

import {DataStore} from "aws-amplify/datastore";
import {signUp, confirmSignUp, autoSignIn} from 'aws-amplify/auth';

import {DevFittrOrder} from "./models";

const $q = useQuasar();
const store = useGlobalStore();
const router = useIonRouter();
const route = useRoute();
const orderListType = ref("history");
const loading = ref(false);
const accordionGroup = ref();
const scrollRef = ref(false);
const registerState = ref('sign-in');

import version from "../package.json";
import logo_penders from "./assets/Logo-Penders-Voetzorg.svg";
import logo_kievit from "./assets/kievit_logo.webp";
import logo_voetmax from "./assets/voetmax.png";
import logo_wittepoel from "./assets/wittepoel.webp";
import logo_footcare from "./assets/logo.png";
import logo_care from "./assets/care_new_logo_white.png";
import logo_fittr from "./assets/fittr_logo.png";

/* Configuration Lookup */
const themeConfig = {
  fittr: {
    app_logo: logo_fittr,
    shareUrl: "https://fittrapp.com/osb/models/",
    redirectUrl: "https://fittrapp.com",
    url: "https://fittrapp.com",
  },
  footcare: {
    app_logo: logo_footcare,
    shareUrl: version.test_version === false ? 'https://footcaremaatschoenen.info/osb/models/' : "https://test-app.footcaremaatschoenen.info/osb/models/",
    redirectUrl: version.test_version === false ? 'https://footcaremaatschoenen.info' : "https://test-app.footcaremaatschoenen.info",
    url: "https://footcare.nl",
  },
  penders: {
    app_logo: logo_penders,
    shareUrl: version.test_version === false ? "https://app.pendersvoetzorg.nl/osb/models/" : "https://test-pendersvoetzorg.fittrapp.com/osb/models/",
    redirectUrl: version.test_version === false ? "https://app.pendersvoetzorg.nl" : "https://test-pendersvoetzorg.fittrapp.com",
    url: version.test_version === false ? "https://app.pendersvoetzorg.nl" : "https://test-pendersvoetzorg.fittrapp.com",
  },
  kievit: {
    app_logo: logo_kievit,
    shareUrl: "https://app.kievitorthopedie.nl/osb/models/",
    redirectUrl: "https://app.kievitorthopedie.nl",
    url: "https://app.kievitorthopedie.nl",
  },
  wittepoel: {
    app_logo: logo_wittepoel,
    shareUrl: "https://app.wittepoel.nl/osb/models/",
    redirectUrl: "https://app.wittepoel.nl",
    url: "https://app.wittepoel.nl",
  },
  voetmax: {
    app_logo: logo_voetmax,
    shareUrl: "https://app.voetmax.nl/osb/models/",
    redirectUrl: "https://app.voetmax.nl",
    url: "https://app.voetmax.nl",
  },
  care: {
    app_logo: logo_care,
    shareUrl: "https://careorthopedieapp.nl/osb/models/",
    redirectUrl: "https://careorthopedieapp.nl",
    url: "https://careorthopedieapp.nl",
  },
};

/* Assign Configuration */
store.organisational_theme = version.organisation;
store.test = version.test;
console.log(store.test)
const config = themeConfig[version.organisation] || themeConfig['fittr']; // Default to an empty object if theme not found
store.app_logo = config.app_logo || logo_fittr; // Fallback to fittr logo
store.shareUrl = config.shareUrl;
store.redirectUrl = config.redirectUrl;
store.url = config.url;

async function currentAuthenticatedUser() {
  try {
    store.logged_in_user = await fetchUserAttributes();
    store.signedIn = true;
    console.log(store.logged_in_user)
    if (store.logged_in_user['custom:organisation'].includes('Penders') || store.logged_in_user['custom:organisation'].includes('Kievit')) {
      store.organisation = 'penders';
    }
    if (store.logged_in_user['custom:organisation'].includes('Hanssen')) {
      store.organisation = 'hanssen';
    }
  } catch (err) {
    store.logged_in_user = undefined;
    store.signedIn = false;
    console.log("There is no logged in user");
  }
}

const reload = () => {
  window.location.reload();
};

const doRefresh = async (event) => {
  loading.value = false;
  console.log("Begin async operation");
  await store.getSubmittedOrders();
  await store.getArchivedOrders();
  setTimeout(() => {
    loading.value = true;
    event.target.complete();
    console.log("Async operation has ended");
  }, 1000);
};


const getOrders = async () => {
  if (store.logged_in_user) {
    store.submittedOrders = await store.getSubmittedOrders();
    store.archivedOrders = await store.getArchivedOrders();
    setTimeout(async () => {
      loading.value = true;
    }, 1000);
  }
};

const closeAccordion = () => {
  if (accordionGroup.value) {
    scrollRef.value = false;
    accordionGroup.value.$el.value = undefined;
  }
};

const deleteArchivedOrder = async (id) => {
  const alert = await alertController.create({
    cssClass: "my-custom-class",
    header: "Weet je zeker dat je de order verwijderen?",
    // subHeader: 'selecteer een bestemming voor verwerking van de order',
    translucent: true,
    buttons: [
      {
        text: "Afbreken",
        role: "cancel",
        cssClass: "secondary",
        handler: () => {
          console.log("Confirm Cancel");
        },
      },
      {
        text: "Verwijderen!",
        handler: async () => {
          const todelete = await DataStore.query(DevFittrOrder, id);
          await DataStore.delete(todelete);
          await store.getArchivedOrders();
        },
      },
    ],
  });
  return alert.present();
};

Hub.listen("auth", async ({payload}) => {
  switch (payload.event) {
    case "signedIn":
      try {
        store.logged_in_user = await fetchUserAttributes();
        store.signedIn = true;

        if (store.logged_in_user['custom:organisation'].includes('Penders') || store.logged_in_user['custom:organisation'].includes('Kievit')) {
          store.organisation = 'penders';
        }
        if (store.logged_in_user['custom:organisation'].includes('Hanssen')) {
          store.organisation = 'hanssen';
        }

        $q.notify({
          color: "green-6",
          position: "center",
          icon: "fa-light fa-hand-horns",
          message: "Je bent ingelogd!",
        });

        await store.dbSync();
        await getOrders();

        closeAccordion();
      } catch (error) {
        console.log("user failed to sign in", error);
      }
      console.log("user have been signedIn successfully.");
      break;
    case "signedOut":
      store.logged_in_user = undefined;
      store.signedIn = false;
      store.organisation = 'fittr';

      $q.notify({
        color: "red-6",
        position: "center",
        icon: "fa-light fa-hand-sparkles",
        message: "Je bent uitgelogd!",
      });

      console.log("user have been signedOut successfully.");
      break;
    case "tokenRefresh":
      console.log("auth tokens have been refreshed.");
      break;
    case "tokenRefresh_failure":
      console.log("failure while refreshing auth tokens.");
      break;
    case "signInWithRedirect":
      console.log("signInWithRedirect API has successfully been resolved.");
      break;
    case "signInWithRedirect_failure":
      console.log("failure while trying to resolve signInWithRedirect API.");
      break;
  }
});

const onScrollFirst = (position) => {
  if (position.detail.currentY > 70) {
    scrollRef.value = true;
  } else if (position.detail.currentY < 70) {
    scrollRef.value = false;
  }
};

async function reorder(order) {
  store.activeOrder = await DataStore.query(
      DevFittrOrder,
      order.id
  );
  let temp;
  store.activeOrder.formData.colornumber
      ? (temp = await DataStore.query(
          store.Models,
          (c) =>
              c.and((c) => [
                c.modelnumber.eq(
                    store.activeOrder.formData
                        ?.modelnumber
                ),
                c.colornumber.contains(
                    store.activeOrder?.formData
                        ?.colornumber
                ),
              ])
      ))
      : (temp = await DataStore.query(
          store.Models,
          (c) =>
              c.modelnumber.eq(
                  store.activeOrder.formData?.modelnumber
              )
      ));
  if (!temp[0]) {
    $q.notify({
      color: "red-5",
      position: "center",
      textColor: "white",
      icon: "fas fa-triangle-exclamation",
      message:
          "Het lijkt erop dat dit model niet meer in de database staat. Kies een ander model of controleer of het model nog bestaat.",
    });
  } else {
    store.activeModel = temp[0];
    await menuController.close('menu');
    router.push(
        '/osb/order/reorder_' + order.id
    );
  }
}

currentAuthenticatedUser();

const email = ref();
const voornaam = ref();
const achternaam = ref();
const organisation = ref();
const phonenumber = ref();
const password = ref();
const confirmation_code = ref();
const show_user_confirmation_form = ref(false);

async function handleSignUp() {
  try {
    const {isSignUpComplete, userId, nextStep} = await signUp({
      username: email.value,
      password: password.value,
      options: {
        userAttributes: {
          email: email.value,
          phone_number: '+31' + phonenumber.value, // E.164 number convention
          given_name: voornaam.value,
          family_name: achternaam.value,
          'custom:order_editor': 'false',
          'custom:isAdmin': 'false',
          'custom:organisation': organisation.value,
          'custom:ordering_auth': 'true',
        },
        autoSignIn: true
      }
    });
    console.log(userId);
    console.log(nextStep);
    console.log(isSignUpComplete);

    if (nextStep.signUpStep === 'CONFIRM_SIGN_UP') {
      show_user_confirmation_form.value = true;
    }

  } catch (e) {
    console.log(e);
  }
}

async function handleSignUpConfirmation() {
  try {
    const { isSignUpComplete, nextStep } = await confirmSignUp({
      username: email.value,
      confirmationCode: confirmation_code.value,
      options: {
        clientMetadata: {username: email.value} // Optional, an object of key-value pairs which can contain any key and will be passed to your Lambda trigger as-is.
      }
    });
    console.log(nextStep);
    console.log(isSignUpComplete);
    if (nextStep.signUpStep === 'COMPLETE_AUTO_SIGN_IN') {
      await handleAutoSignIn()
      registerState.value = 'sign-in';
    }
  } catch (error) {
    console.log('error confirming sign up', error);
  }
}

async function handleAutoSignIn() {
  try {
    const signInOutput = await autoSignIn();
    // handle sign-in steps
    console.log(signInOutput);
  } catch (error) {
    console.log(error);
  }
}

function sendSupportEmail() {
  window.location.href = "mailto:info@fittrapp.nl?subject=Support request!&body=";
}

onMounted(() => {
  store.accordionGroup = accordionGroup.value.$el;
})
</script>

<template>
  <ion-app :class="store.organisational_theme">
    <ion-menu style="--background: #f7f7f7" :backdrop="true" :visible="true" side="end" @ion-will-open="async () => await getOrders()" @ion-did-close="() => closeAccordion()" content-id="menu" menu-id="menu">
      <ion-toolbar mode="ios" color="primary" style="
          position: absolute;
          top: 0;
          padding-top: var(--ion-safe-area-top);
        ">
        <ion-buttons mode="ios" slot="start">
          <ion-button @click="() => menuController.close()">Close</ion-button>
        </ion-buttons>
        <ion-title mode="ios" v-if="store.logged_in_user">{{ "Hallo " + store.logged_in_user?.given_name }}!
        </ion-title>
        <ion-buttons mode="ios" v-if="store.logged_in_user" slot="end" style="padding-right: 20px">
          <ion-button v-if="store.logged_in_user['custom:isAdmin']?.includes('true')" @click="
              () => {
                router.push('/admin');
                menuController.close();
              }
            " fill="solid" color="secondary">
            <i style="font-size: 26px; color: rgba(255, 255, 255, 0.9)" class="fa-light fa-tools"></i>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>

      <ion-header mode="ios" collapse="fade" :style="[
          scrollRef !== false ? 'z-index:600;opacity:1' : 'z-index:0;opacity:0',
        ]" style="border: none; top: 50px; transition: opacity 0.25s linear">
        <ion-toolbar mode="ios" style="background: #f7f7f7; --border-style: hidden; padding-top: 0">
          <ion-buttons mode="ios" style="width: 86%; margin-left: 7%">
            <ion-searchbar inputmode="search" debounce="1500" mode="ios" style="padding-bottom: 0; margin-top: 25px; background: #f7f7f7" @ion-input="store.searchSubmittedOrders($event.target.value)"></ion-searchbar>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-list mode="ios" style="
          z-index: 50;
          max-height: 100vh;
          height: 100%;
          background: #f7f7f7;
        ">
        <ion-accordion-group ref="accordionGroup" expand="inset" style="z-index: 50; width: 90%; margin-left: 5%; margin-right: 5%">
          <ion-accordion class="order" style="max-height: 95vh; background: #f7f7f7" v-if="store.logged_in_user" value="orders">
            <ion-item mode="ios" :style="[
                scrollRef === false
                  ? 'z-index:500;opacity:1'
                  : 'z-index:0:opacity:0',
              ]" style="--background: #f7f7f7; transition: opacity 0.25s linear" ref="orderHistoryContainer" slot="header" button @click="async () => await getOrders()">
              <ion-avatar slot="start" style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                ">
                <i class="fa-regular fa-clipboard-list" style="font-size: 20px"></i>
              </ion-avatar>
              <ion-label>Order menu</ion-label>
            </ion-item>

            <ion-list slot="content" style="min-height: 90vh; background: #f7f7f7">
              <ion-content mode="ios" :scroll-events="true" @ion-scroll="onScrollFirst" style="min-height: 90vh">
                <ion-refresher style="background: transparent" slot="fixed" @ion-refresh="doRefresh($event)">
                  <ion-refresher-content style="background: transparent"></ion-refresher-content>
                </ion-refresher>

                <ion-header style="display: block" mode="ios" collapse="condense">
                  <ion-toolbar mode="ios" style="--background: #f7f7f7">
                    <ion-buttons mode="ios" style="width: 100%; padding-top: 8px">
                      <ion-segment mode="ios" :value="orderListType" style="width: 100%">
                        <ion-segment-button mode="ios" layout="icon-start" value="history" @click="orderListType = 'history'">
                          <i class="fa-regular fa-history" style="margin-right: 5px"></i>
                          <ion-label>Order historie</ion-label>
                        </ion-segment-button>
                        <ion-segment-button mode="ios" v-if="!(store.archivedOrders.length > 0)" disabled layout="icon-start" style="padding-left: 15px; padding-right: 15px" value="archived" @click="orderListType = 'archived'">
                          <i class="fa-regular fa-archive" style="margin-right: 5px"></i>
                          <ion-label>Opgeslagen orders</ion-label>
                        </ion-segment-button>
                        <ion-segment-button mode="ios" v-if="store.archivedOrders.length > 0" layout="icon-start" style="padding-left: 5px; padding-right: 5px" value="archived" @click="orderListType = 'archived'">
                          <i class="fa-regular fa-archive" style="margin-right: 5px"></i>
                          <ion-label>Opgeslagen orders</ion-label>
                          <ion-badge color="danger" style="
                              margin-inline-start: 5px;
                              color: white;
                              display: flex;
                              justify-content: center;
                            ">
                            {{ store.archivedOrders.length }}
                          </ion-badge>
                        </ion-segment-button>
                      </ion-segment>
                    </ion-buttons>
                  </ion-toolbar>
                  <ion-toolbar mode="ios" style="--background: #f7f7f7">
                    <ion-buttons mode="ios">
                      <ion-searchbar inputmode="search" debounce="1500" mode="ios" style="margin-top: 10px; background: #f7f7f7"
                                     @ion-input="orderListType === 'archived' ? store.searchArchivedOrders($event.target.value) : store.searchSubmittedOrders($event.target.value)"></ion-searchbar>
                    </ion-buttons>
                  </ion-toolbar>
                </ion-header>

                <ion-list v-if="loading === true && orderListType === 'history'" style="background: #f7f7f7; padding-bottom: 100px">
                  <ion-card v-for="order in store.submittedOrders" :key="order.id">
                    <template v-if="order !== 'last_item'">
                      <ion-card-header style="padding: 5px 20px 16px">
                        <ion-grid>
                          <ion-row>
                            <ion-col size="4" style="
                                padding: 0;
                                margin: 0;
                                display: flex;
                                align-items: end;
                              ">
                              <ion-card-subtitle style="width: 100%; font-weight: 800">
                                Kenmerk:
                              </ion-card-subtitle>
                            </ion-col>
                            <ion-col size="8" style="padding: 0; margin: 0">
                              <ion-card-title style="width: 100%">
                                <ion-label style="font-size: 0.6em">{{
                                    order?.orderIdOne +
                                    " - " +
                                    order?.orderIdTwo
                                                                    }}
                                </ion-label>
                              </ion-card-title>
                            </ion-col>
                          </ion-row>
                          <q-separator style="margin-bottom: 10px"/>
                          <ion-row>
                            <ion-col size="4" style="padding: 0; margin: 0">
                              <ion-card-subtitle style="width: 100%; font-weight: 800">
                                Ingediend:
                              </ion-card-subtitle>
                            </ion-col>
                            <ion-col size="8" style="padding: 0; margin: 0">
                              <ion-card-subtitle style="width: 100%">
                                <ion-label>
                                  {{
                                    order.submittedAt
                                  }}
                                </ion-label>
                              </ion-card-subtitle>
                            </ion-col>
                          </ion-row>
                          <q-separator style="margin-bottom: 10px"/>
                          <ion-row>
                            <ion-col size="4" style="padding: 0; margin: 0">
                              <ion-card-subtitle style="width: 100%; font-weight: 800">
                                Locatie:
                              </ion-card-subtitle>
                            </ion-col>
                            <ion-col size="8" style="padding: 0; margin: 0">
                              <ion-card-subtitle style="width: 100%">
                                {{
                                  order.order_destination ===
                                  "allard@cachet-it.nl"
                                      ? "test locatie"
                                      : order.order_destination
                                }}
                              </ion-card-subtitle>
                            </ion-col>
                          </ion-row>
                          <q-separator style="margin-bottom: 10px"/>
                          <ion-row>
                            <ion-col size="4" style="padding: 0; margin: 0">
                              <ion-card-subtitle style="width: 100%; font-weight: 800">
                                Status:
                              </ion-card-subtitle>
                            </ion-col>
                            <ion-col size="8" style="padding: 0; margin: 0">
                              <ion-card-subtitle style="width: 100%">
                                {{ order.status }}
                              </ion-card-subtitle>
                            </ion-col>
                          </ion-row>
                          <q-separator style="margin-bottom: 10px"/>
                        </ion-grid>
                      </ion-card-header>
                      <ion-card-content style="
                          margin-bottom: 15px;
                          display: flex;
                          justify-content: space-around;
                        ">
                        <ion-button size="small" color="medium" fill="solid" style="--color: white" @click="reorder(order)">
                          <ion-label style="color: white"><i class="fa-light fa-clone" style="margin-right: 5px"></i>Nabestelling
                          </ion-label>
                        </ion-button>
                        <!--                      </template>-->
                        <ion-button size="small" color="primary" fill="solid" style="--color: white" @click="
                            () => {
                              menuController.close('menu').then(() => {
                                router.push('/print-form/' + order.id);
                              });
                            }
                          ">
                          <ion-label style="color: white"><i class="fa-light fa-memo-pad" style="margin-right: 5px"></i>Formulier
                          </ion-label>
                        </ion-button>
                      </ion-card-content>
                    </template>
                    <template v-else>
                      <ion-card-header>
                        Deze lijst bevat maximaal de 20 meest recente orders. Voor oudere
                        bestellingen, gebruik de zoekfunctie.
                      </ion-card-header>
                    </template>
                  </ion-card>
                </ion-list>
                <ion-list v-else-if="
                    store.archivedOrders.length > 0 &&
                    orderListType === 'archived'
                  " style="background: #f7f7f7; padding-bottom: 100px">
                  <ion-card v-for="order in store.archivedOrders" :key="order.id">
                    <ion-card-header style="padding: 5px 20px 16px">

                      <ion-grid>
                        <ion-row>
                          <ion-col size="4" style="
                                padding: 0;
                                margin: 0;
                                display: flex;
                                align-items: end;
                              ">
                            <ion-card-subtitle style="width: 100%; font-weight: 800">
                              Kenmerk:
                            </ion-card-subtitle>
                          </ion-col>
                          <ion-col size="8" style="padding: 0; margin: 0">
                            <ion-card-title style="width: 100%">
                              <ion-label style="font-size: 0.6em">{{
                                  order?.orderIdOne +
                                  " - " +
                                  order?.orderIdTwo
                                                                  }}
                              </ion-label>
                            </ion-card-title>
                          </ion-col>
                        </ion-row>
                        <q-separator style="margin-bottom: 10px"/>
                        <ion-row>
                          <ion-col size="4" style="padding: 0; margin: 0">
                            <ion-card-subtitle style="width: 100%; font-weight: 800">
                              Ingediend:
                            </ion-card-subtitle>
                          </ion-col>
                          <ion-col size="8" style="padding: 0; margin: 0">
                            <ion-card-subtitle style="width: 100%">
                              <ion-label>
                                {{
                                  order.submittedAt
                                }}
                              </ion-label>
                            </ion-card-subtitle>
                          </ion-col>
                        </ion-row>
                        <q-separator style="margin-bottom: 10px"/>
                        <ion-row>
                          <ion-col size="4" style="padding: 0; margin: 0">
                            <ion-card-subtitle style="width: 100%; font-weight: 800">
                              Status:
                            </ion-card-subtitle>
                          </ion-col>
                          <ion-col size="8" style="padding: 0; margin: 0">
                            <ion-card-subtitle style="width: 100%">
                              {{ order.status }}
                            </ion-card-subtitle>
                          </ion-col>
                        </ion-row>
                        <q-separator style="margin-bottom: 10px"/>
                      </ion-grid>
                    </ion-card-header>
                    <ion-card-content style="
                        margin-bottom: 15px;
                        display: flex;
                        justify-content: space-around;
                      ">
                      <ion-button size="small" color="danger" fill="solid" @click="
                          () => {
                            deleteArchivedOrder(order.id);
                          }
                        ">
                        <ion-label style="color: white">
                          <i class="fa-light fa-trash-can" style="margin-right: 5px"></i>
                          Verwijder
                        </ion-label>
                      </ion-button>

                      <ion-button size="small" color="primary" fill="solid" style="--color: white" @click="
                          async () => {
                            store.activeOrder = await DataStore.query(
                              DevFittrOrder,
                              order.id
                            );
                            let temp;
                            if (store.activeOrder.formData.colornumber) {
                              temp = await DataStore.query(store.Models, (c) =>
                                c.and((c) => [
                                  c.modelnumber.eq(
                                    store.activeOrder.formData?.modelnumber
                                  ),
                                  c.colornumber.contains(
                                    store.activeOrder?.formData?.colornumber
                                  ),
                                ])
                              );
                            } else {
                              temp = await DataStore.query(store.Models, (c) =>
                                c.modelnumber.eq(
                                  store.activeOrder.formData?.modelnumber
                                )
                              );
                            }
                            store.activeModel = temp[0];
                            await menuController.close('menu');
                            router.push(
                              '/osb/order/archived_' + order.id
                            );
                          }
                        ">
                        <ion-label style="color: white">
                          <i class="fa-light fa-memo-circle-check" style="margin-right: 5px"></i>Verder
                        </ion-label>
                      </ion-button>
                    </ion-card-content>
                  </ion-card>
                </ion-list>

                <ion-list v-else style="background: #f7f7f7">
                  <ion-card v-for="item in 5" :key="item">
                    <ion-card-header style="padding: 5px 20px 16px">
                      <q-skeleton animation="fade" style="position: relative; left: 95%; top: 5px" height="24px" width="24px" type="rect"></q-skeleton>

                      <ion-row style="margin-top: 10px">
                        <ion-col size="6" style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          ">
                          <ion-card-subtitle>
                            <q-skeleton animation="fade" type="text" width="70%"></q-skeleton>
                          </ion-card-subtitle>
                        </ion-col>

                        <ion-col size="6" style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          ">
                          <ion-card-title>
                            <q-skeleton animation="fade" type="text" style="top: -2px" width="60%"></q-skeleton>
                          </ion-card-title>
                        </ion-col>

                        <ion-col size="6" style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          ">
                          <ion-card-subtitle>
                            <q-skeleton animation="fade" type="text" width="80%"></q-skeleton>
                          </ion-card-subtitle>
                        </ion-col>

                        <ion-col size="6" style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          ">
                          <ion-card-title>
                            <q-skeleton animation="fade" type="text" style="top: -2px" width="80%"></q-skeleton>
                          </ion-card-title>
                        </ion-col>

                        <ion-col size="6" style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          ">
                          <ion-card-subtitle>
                            <q-skeleton animation="fade" type="text" width="60%"></q-skeleton>
                          </ion-card-subtitle>
                        </ion-col>

                        <ion-col size="6" style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          ">
                          <ion-card-subtitle>
                            <q-skeleton animation="fade" type="text" width="70%"></q-skeleton>
                          </ion-card-subtitle>
                        </ion-col>

                        <ion-col size="6" style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          ">
                          <ion-card-subtitle>
                            <q-skeleton animation="fade" type="text" width="40%"></q-skeleton>
                          </ion-card-subtitle>
                        </ion-col>

                        <ion-col size="6" style="
                            padding-top: 0;
                            padding-bottom: 0;
                            margin-top: 0;
                            margin-bottom: 0;
                          ">
                          <ion-card-subtitle>
                            <q-skeleton animation="fade" type="text" width="60%"></q-skeleton>
                          </ion-card-subtitle>
                        </ion-col>
                      </ion-row>

                      <q-separator/>
                    </ion-card-header>

                    <ion-card-content style="
                        margin-bottom: 15px;
                        display: flex;
                        justify-content: space-around;
                      ">
                      <q-skeleton animation="fade" type="QBtn" height="40px"></q-skeleton>

                      <q-skeleton animation="fade" type="QBtn" height="40px"></q-skeleton>
                    </ion-card-content>
                  </ion-card>
                </ion-list>
              </ion-content>
            </ion-list>
          </ion-accordion>

          <ion-accordion value="colors" style="background: #f7f7f7">
            <ion-item style="--background: #f7f7f7" slot="header" button>
              <ion-avatar slot="start" style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                ">
                <i v-if="store.logged_in_user" class="fa-regular fa-user-check" style="font-size: 20px"></i>
                <i v-else class="fa-regular fa-user-xmark" style="font-size: 20px"></i>
              </ion-avatar>
              <ion-label>Account</ion-label>
              <!--              <ion-label><p>{{ user.attributes.given_name + ' ' + user.attributes.family_name }}</p></ion-label>-->
            </ion-item>

            <div slot="content" style="
                --background: #f7f7f7;
                --padding-inline-start: 0;
                --padding-inline-end: 0;
                --padding-start: 0;
                padding: 20px 0 20px 0;
              ">
              <ion-segment v-if="!store.logged_in_user && store.url.includes('fittrapp')" :value="registerState" style="width: 100%">
                <ion-segment-button layout="icon-start" value="sign-in" @click="registerState = 'sign-in'">
                  <i class="fa-regular fa-right-to-bracket" style="margin-right: 5px"></i>
                  <ion-label>Sign in</ion-label>
                </ion-segment-button>
                <ion-segment-button layout="icon-start" style="padding-left: 15px; padding-right: 15px" value="sign-up" @click="registerState = 'sign-up'">
                  <i class="fa-regular fa-user-plus" style="margin-right: 5px"></i>
                  <ion-label>Sign up</ion-label>
                </ion-segment-button>
              </ion-segment>

              <authenticator v-if="registerState === 'sign-in'" :hide-sign-up="true" :login-mechanisms="['email']">
                <template v-slot="{ signOut }">
                  <div style="
                      width: 100%;
                      padding-top: 10px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    ">
                    <ion-button v-if="store.logged_in_user" capitalize color="danger" @click="signOut">
                      <ion-text style="font-size: 16px">
                        <i class="fa-regular fa-sign-out" style="font-size: 16px; margin-right: 5px"></i>|
                      </ion-text>
                      <ion-text style="font-size: 16px; margin-left: 5px">Uitloggen
                      </ion-text>
                    </ion-button>
                  </div>
                </template>
              </authenticator>

              <q-form v-if="registerState === 'sign-up' && show_user_confirmation_form === false" greedy>
                <q-card-section class="register-padding">

                  <ion-label>Email</ion-label>
                  <q-input class="input-margin" dense outlined v-model="email" type="text" :rules="[
                    (val) => (val && val.length > 0) || '*Dit veld is verplicht',
                  ]"/>

                  <ion-label>Password</ion-label>
                  <q-input class="input-margin" dense outlined v-model="password" type="password" :rules="[
                    (val) => (val && val.length > 0) || '*Dit veld is verplicht',
                  ]"/>

                  <ion-label>Voornaam</ion-label>
                  <q-input class="input-margin" dense outlined v-model="voornaam" type="text" :rules="[
                    (val) => (val && val.length > 0) || '*Dit veld is verplicht',
                  ]"/>

                  <ion-label>Achternaam</ion-label>
                  <q-input class="input-margin" dense outlined v-model="achternaam" type="text" :rules="[
                    (val) => (val && val.length > 0) || '*Dit veld is verplicht',
                  ]"/>

                  <ion-label>Organisatie</ion-label>
                  <q-input class="input-margin" dense outlined v-model="organisation" type="text" :rules="[
                    (val) => (val && val.length > 0) || '*Dit veld is verplicht',
                  ]"/>

                  <ion-label>Telefoonnummer</ion-label>
                  <q-input class="input-margin" dense outlined v-model="phonenumber" type="tel" :rules="[
                    (val) => (val && val.length > 0) || '*Dit veld is verplicht',
                  ]"/>

                </q-card-section>

                <q-card-section style="padding:0 32px 0 32px">
                  <q-btn style="width:100%" color="primary" label="Sign up" no-caps type="button" @click="handleSignUp"/>
                </q-card-section>
                <!--        <pre>{{ formData }}</pre>-->
              </q-form>

              <q-form v-if="registerState === 'sign-up' && show_user_confirmation_form === true" greedy>
                <q-card-section class="register-padding">

                  <ion-label>Confirmation code</ion-label>
                  <q-input class="input-margin" dense outlined v-model="confirmation_code" type="text" :rules="[
                    (val) => (val && val.length > 0) || '*Dit veld is verplicht',
                  ]"/>

                </q-card-section>

                <q-card-section style="padding:0 32px 0 32px">
                  <q-btn style="width:100%" color="primary" label="Confirm" no-caps type="button" @click="handleSignUpConfirmation"/>
                </q-card-section>
                <!--        <pre>{{ formData }}</pre>-->
              </q-form>
            </div>
          </ion-accordion>

          <ion-accordion style="border-bottom: solid 1px rgba(25, 25, 25, 0.25)" value="help">
            <ion-item slot="header" style="--background: #f7f7f7" button>
              <ion-avatar slot="start" style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                ">
                <i class="fa-regular fa-comments-question-check" style="font-size: 20px"></i>
              </ion-avatar>
              <ion-label> Help</ion-label>
            </ion-item>

            <ion-list slot="content" style="width: 80%; margin-left: 10%; margin-bottom: 10px">
              <ion-item @click="() => reload()" button lines="full">
                <ion-avatar slot="start" style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  ">
                  <i class="fa-regular fa-arrow-circle-right" style="font-size: 20px"></i>
                </ion-avatar>
                <ion-label>Reload app</ion-label>
                <!--                <ion-button @click="() => insert()">Restart</ion-button>-->
              </ion-item>
              <ion-item @click="sendSupportEmail" button lines="full">
                <ion-avatar slot="start" style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  ">
                  <i class="fa-regular fa-comment-exclamation" style="font-size: 20px"></i>
                </ion-avatar>
                <ion-label>Support</ion-label>
                <!--                <ion-button @click="() => insert()">Restart</ion-button>-->
              </ion-item>
<!--              <ion-item button lines="full">
                <ion-avatar slot="start" style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  ">
                  <i class="fa-regular fa-code-branch" style="font-size: 20px"></i>
                </ion-avatar>
                <ion-label> Version: {{ version.version }}</ion-label>
              </ion-item>-->
            </ion-list>
          </ion-accordion>
        </ion-accordion-group>
        <ion-label style="position: absolute;
    bottom: 10px;
   font-size: 18px;
    z-index: 100000;
    text-align: center;
    width: 100%;"> App version: {{ version.version }}</ion-label>
      </ion-list>
    </ion-menu>

    <ion-router-outlet id="menu"/>
  </ion-app>
</template>
<style>
.amplify-field-group :not(:first-child) .amplify-input {
  border-start-start-radius: 4px;
  border-end-start-radius: 4px;
}

[data-amplify-authenticator] [data-amplify-container] {
  position: relative;
  place-self: center;
  width: 25rem !important;
}

[data-amplify-authenticator] [data-amplify-router] {
  border: none;
}

.amplify-field-group :not(:first-child) .amplify-input {
  border-start-start-radius: 4px;
  border-end-start-radius: 4px;
}

[data-amplify-authenticator] [data-amplify-container] {
  --width: var(--amplify-components-authenticator-container-width-max);
}

.amplify-heading {
  color: var(--ion-color-dark);
}

[data-amplify-authenticator] {
  --amplify-components-authenticator-container-width-max: 100%;
  --amplify-colors-background-primary: var(--ion-color-primary);
  --amplify-colors-background-secondary: var(--ion-color-secondary);
  --amplify-colors-background-tertiary: var(--ion-color-tertiary);
  --amplify-colors-border-primary: transparent;
  --amplify-colors-border-secondary: transparent;
  --amplify-colors-border-tertiary: transparent;
  --amplify-colors-brand-primary-10: var(--amplify-colors-teal-100);
  --amplify-colors-brand-primary-20: var(--amplify-colors-teal-90);
  --amplify-colors-brand-primary-40: var(--amplify-colors-teal-80);
  --amplify-colors-brand-primary-60: var(--amplify-colors-teal-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-teal-40);
  --amplify-colors-brand-primary-90: var(--amplify-colors-teal-20);
  --amplify-colors-brand-primary-100: var(--amplify-colors-teal-10);
  --amplify-colors-font-interactive: var(--amplify-colors-teal-80);
  --amplify-components-heading-color: var(--amplify-colors-neutral-20);
  --amplify-components-tabs-item-active-border-color: var(--ion-color-primary);
  --amplify-components-tabs-item-active-color: var(--ion-color-tertiary);
  --amplify-components-tabs-item-color: var(--ion-color-primary);
  --amplify-components-tabs-item-focus-color: var(--ion-color-primary);
  --amplify-components-text-color: var(--amplify-colors-font-interactive);
}

[data-amplify-authenticator] [data-state="inactive"] {
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-dark);
  opacity: 0.5;
}

[data-amplify-authenticator] [data-amplify-router] {
  border: none;
  background-color: transparent;
}
</style>
<style scoped>
.register-padding {
  padding: 16px 32px 0 32px;
}

.input-margin {
  margin-top: 8px;
}

ion-menu {
  --width: 100%;
}

ion-menu ion-content {
  --width: 100%;
}

ion-menu ion-card {
  width: 90%;
  margin-left: 5%;
  box-sizing: border-box;
}

ion-menu ion-card-content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

ion-menu ion-content ion-content {
  padding-top: 0;
}

ion-accordion.accordion-expanding ion-item[slot="header"],
ion-accordion.accordion-expanded ion-item[slot="header"] {
  --color: var(--ion-color-primary);
}

ion-accordion {
  margin: 0 auto;
}

ion-accordion.accordion-expanding,
ion-accordion.accordion-expanded {
  width: calc(90% + 6%);
  margin: 2% auto;
}

@media (min-width: 350px) {
}

/*###############################################################*/
@media (min-width: 576px) {
}

/*###############################################################*/
@media (min-width: 768px) {
  ion-menu {
    --width: 29rem;
  }

  ion-menu ion-content {
    --width: 29rem;
  }
}

/*###############################################################*/
@media (min-width: 992px) {
}

/*###############################################################*/
@media (min-width: 1024px) {
}

/*###############################################################*/
@media (min-width: 1150px) {
}
</style>
